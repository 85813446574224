<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Spartan Energy` : `Spartan Energy`
    }}</template>
  </metainfo>
  <div v-if="!this.isLoading" class="about-page">
    <Page :hasSplash="false">
      <template #pageContent>
        <div
          v-for="(item, index) in this.aboutPageEntity.aboutPageAboutBlocks"
          :key="`about-block-${index}`"
          class="about-block"
        >
          <h1>{{ item.fields.aboutBlockName }}</h1>
          <img
            class="about-block__avatar"
            :src="item.fields.aboutBlockAvatar.fields.file.url"
          />
          <div class="about-block__description">
            <RichTextRenderer :document="item.fields.aboutBlockRichText" />
          </div>
        </div>
        <Panel :header="this.aboutPageEntity.aboutPageHeader" :width="60">
          <template #panelContent>
            <RichTextRenderer
              :document="this.aboutPageEntity.aboutPageRichText"
            />
          </template>
        </Panel>
      </template>
    </Page>
  </div>
</template>

<script lang="ts">
import "./about-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_ABOUT_PAGE } from "@/store/modules/about-page/about-page.actions";
import { ABOUT_PAGE_ENTRY_ID } from "@/store/api.config";
import { AboutPageEntity } from "@/store/modules/about-page/about-page.model";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "AboutPage",
  components: {
    Page,
    Panel,
    RichTextRenderer,
  },
})
export default class AboutPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "About Us",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  aboutPageEntity: AboutPageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_ABOUT_PAGE, ABOUT_PAGE_ENTRY_ID);
    this.aboutPageEntity = this.$store.getters.aboutPageEntity;

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getAboutPageSplashImageUrl(): string {
    return getImageUrlFromContentfulEntity(
      this.aboutPageEntity.aboutPageSplashImage
    );
  }
  //#endregion
}
AboutPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
</script>
