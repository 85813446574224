
import "./about-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_ABOUT_PAGE } from "@/store/modules/about-page/about-page.actions";
import { ABOUT_PAGE_ENTRY_ID } from "@/store/api.config";
import { AboutPageEntity } from "@/store/modules/about-page/about-page.model";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "AboutPage",
  components: {
    Page,
    Panel,
    RichTextRenderer,
  },
})
export default class AboutPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "About Us",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  aboutPageEntity: AboutPageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_ABOUT_PAGE, ABOUT_PAGE_ENTRY_ID);
    this.aboutPageEntity = this.$store.getters.aboutPageEntity;

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getAboutPageSplashImageUrl(): string {
    return getImageUrlFromContentfulEntity(
      this.aboutPageEntity.aboutPageSplashImage
    );
  }
  //#endregion
}
AboutPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
